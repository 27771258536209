@import '../../node_modules/webf/vars-default-theme.scss';
// ici les modifications des variables de webf (surcharge)
@import '../../node_modules/webf/plugins.scss';

html,
.webf-box > .box-body {
    font-size: 14px !important;
    line-height: 20px !important;
}

@mixin placeholder() {
    color: black;
    opacity: 0.54;
    font-size: 13px;
}

::-webkit-input-placeholder {
    @include placeholder();
}

:-moz-placeholder {
    /* Firefox 18- */
    @include placeholder();
}

::-moz-placeholder {
    /* Firefox 19+ */
    @include placeholder();
}

:-ms-input-placeholder {
    @include placeholder();
}

.form-control {
    &::-webkit-input-placeholder {
        @include placeholder();
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @include placeholder();
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @include placeholder();
    }

    &:-ms-input-placeholder {
        @include placeholder();
    }
}

.txt-error {
    background: #ffd !important;
    border-color: #f66 !important;
}

.select-error {
    background: #ffd !important;
    border-color: #f66 !important;
}

.checkbox-error {
    border-color: #f66 !important;
}

.checkbox-error a {
    background-color: #ffd !important;
}

/*.success {
  background: #F0FFF0 !important;
  border-color: #00c000 !important;
  border: 1px solid #006600;
  padding: 5px 10px;
}*/

.error {
    background: #ffffdd !important;
    border-color: #ffd700 !important;
    border: 1px solid #ffd700;
    padding: 5px 10px;
    color: #444556;
    margin: 5px 0 20px;

    & > ul {
        margin: 0;

        & > li {
            cursor: pointer;
            line-height: 1.1em;
            margin: 5px 0;
        }
    }
}

.errors-form {
    display: none;
    background: #ffffdd !important;
    border: 1px solid #ffd700;
    padding: 5px 10px;
    color: #444556;
    margin-bottom: 10px;
}

.hidden-submit {
    position: absolute;
    left: -9999px;
    top: -9999px;
    opacity: 0;
    filter: alpha(opacity=0);
    outline: none;
}

input {
    outline: 0;
    outline: none;

    &[readonly] {
        cursor: not-allowed;
    }
}

textarea[readonly] {
    cursor: not-allowed;
}

.wrap-form {
    padding-bottom: 300px;

    .wrap-h2 {
        border-bottom: 2px solid #0b5ba0;
        padding-bottom: 5px;
        margin-bottom: 15px;

        &:not(:first-child) {
            margin-top: 20px;
        }

        h2 {
            font-size: 25px;
            margin-top: 0;
            margin-bottom: 0;
            color: #0b5ba0;
        }
    }

    .wrap-h3 {
        padding-bottom: 5px;
        margin-bottom: 5px;

        h3 {
            text-decoration: underline;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .button-ajout-element:not(.button-ajout-element-no-margin) {
        margin-bottom: 10px;
    }
}

.wrap-form-dialog {
    .checkbox-type {
        float: left;
        padding-right: 0;
        top: 2px;
    }
}

.with-addon + .input-group-addon {
    cursor: help;
}

@media (max-width: 991px) {
    .wrap-form {
        .form-horizontal .control-label {
            text-align: left;
        }
    }
}

@media (max-width: 767px) {
    .wrap-form {
        .checkbox-type {
            float: left;
            padding-right: 0;
            top: 2px;
        }
    }
}

@media print {
    header {
        display: none;
    }

    .main-container {
        padding-top: 0;

        .page-container {
            .left-side {
                display: none;
            }
            .right-side {
                margin-left: 0;
            }
        }
    }
}
