@import '../vars_smart';

.webf-button {
    font-family: 'Lucida grande', arial, sans-serif;
    padding: 3px 8px;
}

.webf-box .box-header > .webf-button {
    padding-top: 1px;
}

.webf-dialog > .webf-box .box-header .webf-button {
    padding-right: 6px;
    padding-left: 6px;
}

.webf-dialog.dialog-responsive {
    width: 500px;
}

.smart-wrap-checkbox {
    vertical-align: middle;
    border-radius: 2px;
    box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1) inset;
}

.webf-separator.important {
    background-color: #aaa;

    &.secondary {
        background-color: #aaa;
    }
}
